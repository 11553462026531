<template>
    <div class="option_terminal">
        <vs-row>
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                <h3>{{ name }}</h3>
            </vs-col>
        </vs-row>
        <vs-divider/>
        <div v-if="machine != null">
            <vx-card >
                <vs-row class="mb-6" >
                    <vs-col vs-w="6" vs-xs="12" >
                        <vs-col vs-w="4" vs-xs="12" class="mt-2" >
                            <span>Nom</span>
                        </vs-col>
                        <vs-col vs-w="8" vs-xs="12" class="pr-4">
                            <vs-input class="w-full" v-model="machine.name" />
                        </vs-col>
                    </vs-col>
                    <vs-col vs-w="6" class="mt-2">
                        <vs-col vs-w="4" vs-xs="2"  class="pl-4">
                            <span>Type</span>
                        </vs-col>
                        <vs-col vs-w="8" vs-xs="12"  >
                            <vs-col vs-w="6" vs-xs="12"  >
                                <vs-radio v-model="machine.type" vs-name="radios1" vs-value="m"> Individuelle ou &lt; 5 pers. </vs-radio>

                            </vs-col>
                            <vs-col vs-w="6" vs-xs="12"   >
                               <vs-radio v-model="machine.type" vs-name="radios1" vs-value="g"> Cours collectifs </vs-radio>
                            </vs-col>
                            <!-- <vs-radio v-model="machine.type" vs-name="radios1" vs-value="m"> Individuelle ou &lt; 5 pers. </vs-radio>
                            <vs-radio v-model="machine.type" vs-name="radios1" vs-value="g"> Cours collectifs </vs-radio> -->
                        </vs-col>
                             
              
                    </vs-col>
                </vs-row>
                <vs-row>
<!-- 
                    <vs-col vs-w="6" class="pr-4">
                        <div class="vx-row mb-2">
                            <div class="vx-col sm:w-1/3 w-full pt-2">
                                <span>Type</span>
                            </div>
                             
                            <div class="vx-col sm:w-2/3 w-full mb-2">
                                 <vs-radio v-model="machine.type" vs-name="radios1" vs-value="m"> Individuelle ou &lt; 5 pers. </vs-radio>
                                <vs-radio v-model="machine.type" vs-name="radios1" vs-value="g"> Cours collectifs </vs-radio>
                                 <vs-select v-model="machine.type" class="w-full select-large">
                                    <vs-select-item value="m" text="Individuelle ou < 5 pers." />
                                    <vs-select-item value="g" text="Cours collectifs" />
                                </vs-select> 
                            </div>
                        </div>

                    </vs-col> -->

                    <vs-col vs-w="6" class="pr-4">
                        <div class="vx-row mb-2">
                            <div class="vx-col sm:w-1/3 w-full pt-2">
                                <span>NB personnes</span>
                            </div>
                            <div class="vx-col sm:w-2/3 w-full mb-2">
                                <vs-input class="w-full" type="number" v-model="machine.booking" />
                            </div>
                        </div>
                    </vs-col>
                    <vs-col vs-w="6" class="pl-4">
                       <div class="vx-row mb-2">
                            <div class="vx-col sm:w-1/3 w-full pt-2">
                                <span>Groupe</span>
                            </div>
                            <div class="vx-col sm:w-2/3 w-full mb-2">
                                <vs-input class="w-full" v-model="machine.groupe" />
                            </div>

                        </div>
                    </vs-col>
                </vs-row>

                <!-- <vs-divider />
                
                <vs-row>
                    <vs-col vs-w="6" class="pr-4">
                        <div class="vx-row">
                            <div class="vx-col sm:w-1/3 w-full pt-2">
                                <span>Essais</span>
                            </div>
                            <div class="vx-col sm:w-2/3 w-full">
                                <vs-select v-model="machine.trial" class="w-full select-large">
                                    <vs-select-item value="1" text="Oui" />
                                    <vs-select-item value="0" text="Non" />
                                </vs-select>
                            </div>
                        </div>
                    </vs-col>
                    <vs-col vs-w="6" class="pl-4">
                        <div class="vx-row">
                            
                            <div class="vx-col sm:w-1/3 w-full pt-2">
                                <span>Contractuelles</span>
                            </div>
                            <div class="vx-col sm:w-2/3 w-full">
                                <vs-select v-model="machine.ctrial" class="w-full select-large">
                                    <vs-select-item value="1" text="Oui" />
                                    <vs-select-item value="0" text="Non" />
                                </vs-select>
                            </div>
                        </div>
                    </vs-col>
                </vs-row> -->

                <vs-divider/>

                <vs-row >
                    <vs-col vs-w="6" class="pr-4">
                        <div class="vx-row mb-6">
                            <div class="vx-col sm:w-1/3 w-full pt-2">
                                <span>Crédit</span>
                            </div>
                            <div class="vx-col sm:w-2/3 w-full">
                                <vs-input class="w-full" type="number" v-model="machine.credit" />
                            </div>
                        </div>
                    </vs-col>
                    <vs-col vs-w="6" class="pl-4">
                        <vs-col vs-w="4" >
                            <vs-col vs-w="2" vs-xs="2"  >
                                 <span>Web</span>
                            </vs-col>
                        </vs-col>
                        <vs-col vs-w="8" vs-xs="12" vs-type="display" vs-justify="center" vs-align="center" >
                            <vs-col vs-w="6" vs-xs="12"  >
                                <vs-radio v-model="machine.web" vs-name="radios2" vs-value="1"> Oui </vs-radio>

                            </vs-col>
                            
                            <vs-col vs-w="6" vs-xs="12"   >
                                <vs-radio v-model="machine.web" vs-name="radios2" vs-value="0"> Non </vs-radio>
                            </vs-col>
                            <!-- <vs-radio v-model="machine.type" vs-name="radios1" vs-value="m"> Individuelle ou &lt; 5 pers. </vs-radio>
                            <vs-radio v-model="machine.type" vs-name="radios1" vs-value="g"> Cours collectifs </vs-radio> -->
                        </vs-col>
                             
              
                    </vs-col>
                       
                   
                </vs-row>

                 <vs-divider/>

                 <vs-row>
                    <vs-col vs-w="6" class="pr-4">
                        <div class="vx-row mb-6">
                            <div class="vx-col sm:w-1/3 w-full pt-2">
                                <span>Date de début : </span>
                            </div>
                            <div class="vx-col sm:w-2/3 w-full">
                                <cDate v-model="machine.debut" class="w-full select-large"/>
                            </div>
                        </div>
                    </vs-col>
                    <vs-col vs-w="6" class="pl-4">
                        <div class="vx-row mb-6">
                            <div class="vx-col sm:w-1/3 w-full pt-2">
                                <span> Date de fin : </span>
                            </div>
                            <div class="vx-col sm:w-2/3 w-full">
                                <cDate v-model="machine.fin" class="w-full select-large"/>
                            </div>
                        </div>
                    </vs-col>
                </vs-row>

                 <vs-divider/>

                <vs-row>
                    <div class="vx-col sm:w-1/3 w-full pt-2">
                        <span> Couleur </span>
                        
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                            <input  class="w-full" type="color" style="height:40px; width:100%; " v-model="machine.color">
                    </div>
                </vs-row>
                <br>
                <vs-button color="success" type="filled" class="w-full" v-on:click="update()">Modifier</vs-button><br/>
            </vx-card>

            <br/>


















            <vx-card>
                <vs-row>
                    <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                        <h3>Horaires</h3>
                        <vs-button color="success" type="filled" v-on:click="openAddHour" >Ajouter</vs-button>
                    </vs-col>
                </vs-row>
                <vs-divider/>
                <div class="size_min500">
                    <div class="size_cont500">
                        <table class="w-full print_table">
                            <tr>
                                <th class="text-center">Lundi</th>
                                <th class="text-center">Mardi</th>
                                <th class="text-center">Mercredi</th>
                                <th class="text-center">Jeudi</th>
                                <th class="text-center">Vendredi</th>
                                <th class="text-center">Samedi</th>
                                <th class="text-center">Dimanche</th>
                            </tr>
                            <tr>
                                <td>
                                    <div v-for="hour in lhour" :key="hour.uid" class="mt-4">
                                        <div v-if="hour.day == '1' & hour.flag!='e'" class="booking_hour text-center mb-4">
                                            {{ hour.hour }} <vs-icon icon="close" size="20px" color="red" class="cursor" v-on:click="deletehour(hour.uid)"></vs-icon>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-for="hour in lhour" :key="hour.uid" class="mt-4">
                                        <div v-if="hour.day == '2' & hour.flag!='e'" class="booking_hour text-center mb-4">
                                            {{ hour.hour }} <vs-icon icon="close" size="20px" color="red" class="cursor" v-on:click="deletehour(hour.uid)"></vs-icon>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-for="hour in lhour" :key="hour.uid" class="mt-4">
                                        <div v-if="hour.day == '3' & hour.flag!='e'" class="booking_hour text-center mb-4">
                                            {{ hour.hour }} <vs-icon icon="close" size="20px" color="red" class="cursor" v-on:click="deletehour(hour.uid)"></vs-icon>
                                            
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-for="hour in lhour" :key="hour.uid" class="mt-4">
                                        <div v-if="hour.day == '4' & hour.flag!='e'" class="booking_hour text-center mb-4">
                                            {{ hour.hour }} <vs-icon icon="close" size="20px" color="red" class="cursor" v-on:click="deletehour(hour.uid)"></vs-icon>
                                        
                                        </div>
                                       
                                    </div>
                                </td>
                                <td>
                                    <div v-for="hour in lhour" :key="hour.uid" class="mt-4">
                                        <div v-if="hour.day == '5' & hour.flag!='e'" class="booking_hour text-center mb-4">
                                            {{ hour.hour }} <vs-icon icon="close" size="20px" color="red" class="cursor" v-on:click="deletehour(hour.uid)"></vs-icon>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-for="hour in lhour" :key="hour.uid" class="mt-4">
                                        <div v-if="hour.day == '6' & hour.flag!='e'" class="booking_hour text-center mb-4">
                                            {{ hour.hour }} <vs-icon icon="close" size="20px" color="red" class="cursor" v-on:click="deletehour(hour.uid)"></vs-icon>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-for="hour in lhour" :key="hour.uid" class="mt-4">
                                        <div v-if="hour.day == '0' & hour.flag!='e'" class="booking_hour text-center mb-4">
                                            {{ hour.hour }} <vs-icon icon="close" size="20px" color="red" class="cursor" v-on:click="deletehour(hour.uid)"></vs-icon>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="text-center"><vs-button color="danger" type="filled" v-on:click="delDay(1)" >Vider</vs-button></td>
                                <td class="text-center"><vs-button color="danger" type="filled" v-on:click="delDay(2)" >Vider</vs-button></td>
                                <td class="text-center"><vs-button color="danger" type="filled" v-on:click="delDay(3)" >Vider</vs-button></td>
                                <td class="text-center"><vs-button color="danger" type="filled" v-on:click="delDay(4)" >Vider</vs-button></td>
                                <td class="text-center"><vs-button color="danger" type="filled" v-on:click="delDay(5)" >Vider</vs-button></td>
                                <td class="text-center"><vs-button color="danger" type="filled" v-on:click="delDay(6)" >Vider</vs-button></td>
                                <td class="text-center"><vs-button color="danger" type="filled" v-on:click="delDay(0)" >Vider</vs-button></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </vx-card>

        </div>
        <h2 v-else >Chargement ...</h2>
        <AddHour ref="addHour" :typeM="this.typ" v-on:refresh="refresh()"/>
    </div>
</template>



<style lang="scss" scoped>
.print_table{
    tr,td,th{
        vertical-align: top;
    }
    .cursor{
        vertical-align: sub;
    }
}
</style>

<script>

import autoRefresh from '@/assets/utils/autoRefresh'

import Machine from '@/database/models/machine'
import BookingHour from '@/database/models/booking_hour'
import cDate from '@/components/app/date'
import AddHour from '@/components/machine/addHour.vue'


export default {
    components:{
        AddHour,
        cDate,
	},
    data(){
        return{
            uid     : '',
            name    : '',
            machine : null,
            lhour   : null,
            couleur   : '', 
            debut:'',
            fin:null,
            typ :'m'
            
        }
	},
	mounted:function()
	{
        //autoRefresh
		autoRefresh.SetCallback(()=>{
            //refresh
            Machine.getMemoryClone( this.$route.params.uid, (data)=>
            {
                this.uid     = data.uid;
                this.name    = data.name;
                this.machine = data;
                this.couleur = data.color;
                this.debut   = data.debut;
                this.fin     = data.fin;

                this.typ     = this.machine.type;
                
            })

            
            //List hour
            BookingHour.getTabAllMemoryKey( this.$route.params.uid, (list_hour)=>
            {
                //triage heur
                list_hour.sort(function(a, b)//trier valeur ( ATTENTION CREE DES DOUBLONS )
                {
                    var ca  = a.hour.toUpperCase();
                    var cb  = b.hour.toUpperCase();
                    return ('' + ca).localeCompare(cb);
                });

                let tab = [];
                for( var i=0; i<list_hour.length; i++ )
                    tab.push( list_hour[i] );

                this.lhour = tab;
                // pb flag à e 
                
                
                
            });
        })
        autoRefresh.refresh()
        
    },
    watch:{
        $route (){
            autoRefresh.refresh()
        },
        
    },
    methods:{
        update()
        {
            
            //update
            Machine.update( this.uid, this.machine , this.couleur)
            .then(()=>{
                this.$emit('refresh')
                alert('Modification enregistrée');
            })
            .catch((err)=>{
                this.msg = 'Mise à jour impossible:<br/>'+err;
            })
        },

        deletehour( uid )
        {
            if( window.confirm("Supprimer le créneaux horaire ?"))
            BookingHour.remove( uid )
            .then(()=>{
                alert('Suppression effectuée');
                autoRefresh.refresh();
            })
        },


        openAddHour()
        {
            
                this.$refs.addHour.openPopup( this.uid,()=>{
                    this.refresh();
                } )
           
        },

        refresh()
        {
            autoRefresh.refresh();
        },


        async delDay( day)
        {
            for( var i=0; i<this.lhour.length; i++)
            {
                let buffer = this.lhour[i];
                if(buffer.day == day)
                    await BookingHour.remove(buffer.uid);
            }
            this.refresh();
        }
    }
};

</script>